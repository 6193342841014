@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

.logo {
  width: 155px;
  height: 45px;
  margin-left: 30px;
  margin-top: 5px;
}

.botlogo{
  width: 28px;
  height: 28px;
  background-color: #eae9e9d0;
  padding: 5px ;
  border-radius: 50%;

}
@media only screen and (max-width: 600px) {
  .logo {
    width: 140px;
    height: 41px;
    margin-left: 0px;
  }
  .botlogo{
    width: 25px;
    height: 25px;
    background-color: #eae9e9d0;
    padding: 5px ;
    border-radius: 50%;
  }
}
.name {
  color: #000000be;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  cursor: pointer;
  font-size: 16px;
}

.getnoti {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .name {
    color: #242b38be;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: weight;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    cursor: pointer;
    font-size: 15px;
  }
}

.chatbot-header {
  margin: 0px;
  background-color: #eaeaea;
  color: #fff;
  padding: 2px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.notifioverflow {
  overflow-y: scroll;
  height: 250px;
}

body {
  background-image: url("../assets/base.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-repeat: no-repeat;
  overflow-x: hidden;
  overflow-y: hidden;
}

.chat-container {
  align-items: center;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  height: 80vh; 
  overflow-y: auto;
  border-radius: 10px;
  transition: overflow-y 0.3s ease;
  -webkit-font-smoothing: antialiased;
  background-color: transparent;
}

.message-containerdiv {
  align-items: center;
  margin: 0 auto;
  padding: 0 !important;
  width: 65%;
  height: auto;
  border-radius: 10px;
  transition: overflow-y 0.3s ease;
  -webkit-font-smoothing: antialiased;
  background-color: transparent;
}


@media  screen and (max-width: 400px) {
  .chat-container {
    height: 75vh; 
  }
  .message-containerdiv {
    width: 95%;
  }
}
@media screen and (max-width: 600px) {
  .chat-container {
    height: 74vh; 
  }
  .message-containerdiv {
    width: 95%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .chat-container {
    height: 80vh; 
  }
  .message-containerdiv {
    width: 85%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .chat-container {
    height: 80vh; 
  }
  .message-containerdiv {
    width: 80%;
  }
}


@media only screen and (min-width: 1025px) {
  .chat-container {
    height: 80vh; 
  }
  .message-containerdiv {
    width: 65%;
  }
}
.You {
  display: flex;
  margin-left: 130px;
  justify-content: flex-end;
  text-align: justify;
  margin-top: 15px;
  margin-bottom: 9px;
  font-size: 15px;
}

.You .message-content {
  border: 1px solid #26549d;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 9px 13px 10px 13px;
  line-height: 1.5;
  color: #fff;
  background-color: #26549d;
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}


.bot {
  display: flex;
  margin-right: 40px;
  justify-content: flex-start;
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 15px;
}

.bot .message-content{
 border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  padding: 6px 6px 6px 6px;
  border: 1px solid #eaeaead8;
  background-color: #eaeaead0;
  line-height: 1.4;
  font-weight: 600;
  font-style: normal;
  font-weight: normal;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .You {
    display: flex;
    margin-left: 80px;
    justify-content: flex-end;
    text-align: justify;
    margin-top: 10px;
    font-size: 15px;
  }

  .You .message-content {
    padding: 6px 12px 8px 13px;
  }

  .bot {
    margin-right: 10px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .You {
    display: flex;
    margin-left: 40px;
    justify-content: flex-end;
    text-align: justify;
    margin-top: 10px;
    font-size: 15px;
  }

  .You .message-content {
    padding: 5px 10px 7px 10px;
  }

  .bot {
    margin-right: 5px;
    padding: 6px;
  }
}

.file-input-container {
  display: inline-block;
  position: relative;
}

input[type="file"] {
  position: absolute;
  left: -9999px;
}

label {
  cursor: pointer;
}

.jv {
  margin: 0px 0px 0px 0px;
}

.down {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 180px;
  margin-top: 10px;
}
.down1 {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 145px;
  margin-top: 0px;
}
.span1 {
  margin-left: 30px;
}
.download {
  padding: 8px 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border: 1px solid#26549d;
  background-color: #26549d;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}

.icon-sent1 {
  font-size: 20px;
  padding: 8px 10px;
  margin-top: 4px;
  margin-right: 5px;
  color: #17458f;
}
.icon-sent3 {
  font-size: 20px;
  color: #17458f;
  padding: 9px 8px;
  margin-top: 4px;
}
.icon-sent2 {
  font-size: 25px;
  padding: 0px 10px;
}

.icon-copy1 {
  color: rgba(0, 0, 0, 0.589);
  margin-top: 5px;
  cursor: pointer;
  font-size: 17px;
}

.icon-share {
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.589);
  margin-left: 15px;
  cursor: pointer;
  font-size: 17px;
}


.message-form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px ;
  background-color: white;
  border: 2px solid #313131fa;
  border-radius: 15px;
  width: 62%;
 
}

@media screen and (max-width: 600px) {
  .message-form {
    width: 100%;
    border-radius: 12px;
    bottom: 30px;
  }
  .down {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: 135px;
    margin-top: 10px;
  }
  .down1 {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-left: 120px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1400px) {
  .message-form {
 
    max-width: 90%;
    border-radius: 12px;
    bottom: 10px;
  }
}
.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.message-form textarea {
  flex: 1;
  resize: none;
  padding: 0 !important;
  border-radius: 0;
  border: none;
  font-size: 16px;
  margin-left: 10px;
  line-height: 45px;
  width: 100%; 
  height: 45px; 
  padding: 0; 
  outline: none;
  box-sizing: border-box;
  overflow: hidden;
}

@media (max-width: 600px) {
  .message-form textarea {
    font-size: 14px;
    line-height: 40px;
    height: 40px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .message-form textarea {
    font-size: 15px;
    line-height: 42px;
    height: 42px;
  }
}

@media (min-width: 1025px) {
  .message-form textarea {
    font-size: 16px;
    line-height: 45px;
    height: 45px;
  }
}
textarea:focus {
  color: #212529;
  border-color: white;
  outline: 0;
  margin-right: 0px;
  border-radius: 5px;
}
.message-button {
  padding: 0px;
  background-color: white;
  color: #17458f;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.file-input-container {
  position: relative;
}

.file-input-container input[type="file"] {
  position: absolute;
  left: -9999px;
}

.file-input-container label {
  cursor: pointer;
  padding: 2px;
  color: #17458f;
  border-radius: 5px;
}

.image-preview {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
}

.delete-icon {
  color: rgb(7, 6, 6);
  position: absolute;
  cursor: pointer;
  z-index: 1;
  border: 2px solid black;
  border-radius:20%;
  padding: 1px;
  font-size:9px;
}

.message-content pre {
  white-space: pre-wrap;
  overflow-x: hidden;
  margin: 0px 0px 0px 0px !important;
}
.pdfimg {
  width: 90px;
}

.profile-menu {
  position: absolute;
  right: 0;
  font-family: Figtree, sans-serif;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  z-index: 100;
  padding: 2px;
  width: 120px;
  margin-top: 17px;
  margin-right: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.profile-menu-ul {
  list-style: none;
  padding: 1px;
  margin: 0;
}

.profile-menu-ul-li {
  cursor: pointer;
  padding: 3px 0;
  text-align: center;
  color: #17458f;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.image-preview {
  max-width: 40px;
  max-height: 40px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
}

.notification-wrapper {
  position: relative;
}
.iconchev {
  font-size: 12px;
}

.notification {
  font-size: 17px;
  color: #17458f;
  cursor: pointer;
}
.notification-menu {
  position: absolute;
  top: 65px;
  width: 420px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 10px;
  z-index: 1000;
  box-shadow: rgba(48, 48, 53, 0.1) 0px 8px 24px,
    rgba(50, 50, 59, 0.1) 0px 16px 56px, rgba(49, 49, 59, 0.1) 0px 24px 80px;
}
.today {
  margin: 0;
  color: #24262b;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  font-size: 13px;
  background-color: white;
}
.yesterday {
  color: #24262b;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  font-size: 13px;
  background-color: white;
}

.notification-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notification-menu ul li {
  padding: 7px 11px;

  color: black;
}

@media only screen and (max-width: 1200px) {
  .notification-menu {
    top: 70px;
    width: 50%;
    border-radius: 8px;
    right: 30px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  }

  .notification-menu ul li {
    padding: 8px;
  }
}
@media only screen and (max-width: 700px) {
  .notification-menu {
    top: 70px;
    width: 90%;
    border-radius: 8px;
    right: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  }

  .notification-menu ul li {
    padding: 8px;
  }
}

.deleteindicate {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  color: rgb(238, 65, 65);
  font-size: 12px;
  font-variation-settings: "slnt" 0;
}
.timenotification{
  font-size: 14px;
  margin-left: 5px;
}
.notification-head {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: #24262b;
  font-size: 15px;
  background-color: white;
  font-variation-settings: "slnt" 0;
}

.notification-hr {
  margin: 0;
  padding: 9px;
  border-bottom: 1px solid #ccc;
}

.pdfimg {
  width: 30px;
  height: 30px;
}

.bull {
  font-size: 11px;
  color: #f7a81b;
}
.custom-font {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
 
}

.notification-bell-container {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(230, 180, 17);
  color: rgb(0, 0, 0);
  border-radius: 50%;
  padding: 1px 5px;
  font-size: 6px;
  font-family: 800;
}
.imagedisplay {
  width: 100%;
  max-width: 400px;
  height: 250px;
}
