.backgroundimg {
    background-image: url("../login/Group.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
  }
  .marginlogin{
    padding-top: 50px;
  }
  
  .registration-Login {
    width: 100%; 
    max-width: 400px;
    margin: 0 auto; 
    padding: 20px;
    border-radius: 30px;
    background-color: transparent;
  }
  
  .loginmbtn {
    width: 100%;
    padding: 10px;
    background-color:#17458f ;
    border: none;
    border-radius: 12px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
  }
  .reg-form {
    width: 100%;
    max-width: 100%;
    margin-top: 1px;
    width: 100%; 
    margin-bottom: 2px; 
    padding: 10px; 
    border-bottom: 1px solid #ccc; 
    border-top: none; 
    border-left: none; 
    border-right: none; 
  
}
.reg-form:focus{
    border-bottom: 1px solid #ccc !important;
    border-top: none !important; 
    border-left: none !important; 
    border-right: none !important; 
    outline: 0;
    box-shadow:none !important;
   
}
.loginhead1{
    text-align: center;
    color: #17458f;
    font-size: 35px;
    font-weight: 300;
}
.loginhead{
    text-align: center;
    color: #17458f;
    font-size: 48px;
    font-weight: 500;
}
.label1{
    color: #17458f;
    margin-top:20px;
    font-size: 15px;
    font-weight:400 ;
}

.image-container1 {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100%;}

.center-image {
    max-width: 100%; 
    max-height: 100%; 
}
